const _temp0 = require("./ar-AE.json");
const _temp1 = require("./bg-BG.json");
const _temp2 = require("./cs-CZ.json");
const _temp3 = require("./da-DK.json");
const _temp4 = require("./de-DE.json");
const _temp5 = require("./el-GR.json");
const _temp6 = require("./en-US.json");
const _temp7 = require("./es-ES.json");
const _temp8 = require("./et-EE.json");
const _temp9 = require("./fi-FI.json");
const _temp10 = require("./fr-FR.json");
const _temp11 = require("./he-IL.json");
const _temp12 = require("./hr-HR.json");
const _temp13 = require("./hu-HU.json");
const _temp14 = require("./it-IT.json");
const _temp15 = require("./ja-JP.json");
const _temp16 = require("./ko-KR.json");
const _temp17 = require("./lt-LT.json");
const _temp18 = require("./lv-LV.json");
const _temp19 = require("./nb-NO.json");
const _temp20 = require("./nl-NL.json");
const _temp21 = require("./pl-PL.json");
const _temp22 = require("./pt-BR.json");
const _temp23 = require("./pt-PT.json");
const _temp24 = require("./ro-RO.json");
const _temp25 = require("./ru-RU.json");
const _temp26 = require("./sk-SK.json");
const _temp27 = require("./sl-SI.json");
const _temp28 = require("./sr-SP.json");
const _temp29 = require("./sv-SE.json");
const _temp30 = require("./tr-TR.json");
const _temp31 = require("./uk-UA.json");
const _temp32 = require("./zh-CN.json");
const _temp33 = require("./zh-TW.json");
module.exports = {
  "ar-AE": _temp0,
  "bg-BG": _temp1,
  "cs-CZ": _temp2,
  "da-DK": _temp3,
  "de-DE": _temp4,
  "el-GR": _temp5,
  "en-US": _temp6,
  "es-ES": _temp7,
  "et-EE": _temp8,
  "fi-FI": _temp9,
  "fr-FR": _temp10,
  "he-IL": _temp11,
  "hr-HR": _temp12,
  "hu-HU": _temp13,
  "it-IT": _temp14,
  "ja-JP": _temp15,
  "ko-KR": _temp16,
  "lt-LT": _temp17,
  "lv-LV": _temp18,
  "nb-NO": _temp19,
  "nl-NL": _temp20,
  "pl-PL": _temp21,
  "pt-BR": _temp22,
  "pt-PT": _temp23,
  "ro-RO": _temp24,
  "ru-RU": _temp25,
  "sk-SK": _temp26,
  "sl-SI": _temp27,
  "sr-SP": _temp28,
  "sv-SE": _temp29,
  "tr-TR": _temp30,
  "uk-UA": _temp31,
  "zh-CN": _temp32,
  "zh-TW": _temp33
}