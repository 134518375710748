enum Routes {
  ADDRESS = "ADDRESS",
  ASSETS = "ASSETS",
  BLOCK = "BLOCK",
  BLOCKS = "BLOCKS",
  HOME = "HOME",
  TRANSACTION = "TRANSACTION",
  ASSET = "ASSET",
}

export default Routes;
